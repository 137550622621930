import React, { useState, useEffect } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { Container as ContainerBase } from 'components/misc/Layouts.js'
import logo from '../../images/logo.png'
import { ReactComponent as FacebookIcon } from '../../images/facebook-icon.svg'
import { ReactComponent as TwitterIcon } from '../../images/twitter-icon.svg'
import { ReactComponent as YoutubeIcon } from '../../images/youtube-icon.svg'
import apiProvider from '../../services/commonService'

const Container = tw(ContainerBase)`bg-primary-500 text-white -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`
const LogoImg = tw.img`w-8`
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`

const SocialLinksContainer = tw.div`mt-10`
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-white`
export default () => {
  const [links, setLinks] = useState({})
  useEffect(() => {
    fetchData()
  }, [])


  const fetchData = async () => {
    const res = await apiProvider.getAll(`content/footer/all`)
    if (res) {
      if (res.result) {
        setLinks(res.result);
      }
    }
  }
  return (
    <Container>
      <Content>
        <Row>
          <Link href='/'>
            <LogoContainer >
              <LogoImg src={logo} />
              <LogoText>CMMC</LogoText>
            </LogoContainer>

          </Link>

          <LinksContainer>
            <Link href='/'>Home</Link>
            <Link href='/about'>About</Link>
            <Link href='/contact-us'>Contact Us</Link>
          </LinksContainer>
          <SocialLinksContainer>
            {
              links?.facebookLink &&
              <SocialLink target="_blank" href={links?.facebookLink}>
                <FacebookIcon />
              </SocialLink>
            }
            {links?.twitterLink &&
              <SocialLink target="_blank" href={links?.twitterLink}>
                <TwitterIcon />
              </SocialLink>
            }
            {links?.youtubeLink &&
              <SocialLink target="_blank" href={links?.youtubeLink}>
                <YoutubeIcon />
              </SocialLink>
            }

          </SocialLinksContainer>
          <CopyrightText>
            &copy; {links?.copyright}
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  )
}
