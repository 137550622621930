import 'tailwindcss/dist/base.css'
import 'styles/globalStyles.css'
import React from 'react'
import { css } from 'styled-components/macro' //eslint-disable-line
import Home from 'Home'

import LoginPage from "pages/Login.js";
import SignupPage from "pages/Signup.js";
import PricingPage from "pages/Pricing.js";
import About from 'pages/AboutUs.js'
import ContactUs from 'pages/ContactUs.js'
import Blog from 'pages/BlogIndex.js'
import GetStarted from "pages/GetStarted";

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

export default function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/about' component={About} exact />
        <Route path='/login' component={LoginPage} exact />
        <Route path='/signup' component={SignupPage} exact />
        <Route path='/pricing' component={PricingPage} exact />
        <Route path='/stepToStart' component={GetStarted} exact />

        <Route path='/contact-us' component={ContactUs} exact />
        <Route path='/blog' component={Blog} exact />
      </Switch>
    </Router>
  )
}
