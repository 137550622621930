import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { useLocation } from 'react-router-dom'

import logo from "images/logo.png";

import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import apiProvider from '../services/commonService'
import { Link } from 'react-router-dom'
import GoogleLoginCustom from './GoogleLogin'
import {  toast } from 'react-toastify'

// import { useForm } from 'react-hook-form'

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

// const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default (props, {

  headingText = "Sign In To CMMC",

  submitButtonText = "Sign In",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "#",
  // signupUrl = "#",

}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  let query = useQuery();
  // const { register, errors, handleSubmit, trigger } = useForm()
  // const [valErrors, setValErrors] = useState({})

  const handleSubmitLogin = async () => {
    const body = {
      email,
      password,
      userType: 'CLIENT'
    }
    const response = await apiProvider.post('auth/login', body)
    if (response) {
      toast.success(response.ResultMessages[0].Message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
        })
    if (response.HasError) {      
    } else {
      localStorage.setItem('user', JSON.stringify(response.result.user))
      localStorage.setItem('token', JSON.stringify(response.result.token))
      if (query.get('location') === 'pricing') {
        props.history.push('/pricing')

      } else {
        props.history.push('/')

      }
    }
  }  
}
const handlePasswordChange = e => {
  // const errs = valErrors
  // if (errs.password) delete errs.password
  setPassword(e.target.value)
  // setValErrors(errs)
}

const handleEmailChange = e => {
  // const errs = valErrors
  // if (errs.email) delete errs.email
  setEmail(e.target.value)
  // setValErrors(errs)
}

return (
  <AnimationRevealPage>
    <Container>
      <Content>
        <MainContainer>
          <LogoLink >
            <LogoImage src={logo} />
          </LogoLink>
          <MainContent>
            <Heading>{headingText}</Heading>
            <FormContainer>
               <SocialButtonsContainer>
           
                     <GoogleLoginCustom props={props}></GoogleLoginCustom>

              </SocialButtonsContainer> 
              <DividerTextContainer>
                <DividerText>Or Sign in with your e-mail</DividerText>
              </DividerTextContainer>
              
                <Input type="email" value={email}   onChange={handleEmailChange}  placeholder="Email" />
                <Input type="password"    value={password}               onChange={handlePasswordChange} placeholder="Password" />
                <SubmitButton disabled={!email || !password} onClick={handleSubmitLogin}>
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
              
              {/* <p tw="mt-6 text-xs text-gray-600 text-center">
                <a href={forgotPasswordUrl} tw="border-b border-gray-500 border-dotted">
                  Forgot Password ?
                </a>
              </p> */}
              <p tw="mt-8 text-sm text-gray-600 text-center">
                Dont have an account?{" "}
                <Link to='/signup' tw="border-b border-gray-500 border-dotted">
                  Sign Up
                </Link>
              </p>
            </FormContainer>
          </MainContent>
        </MainContainer>
     
      </Content>
    </Container>
  </AnimationRevealPage>
);
}
