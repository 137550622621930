
import axios from 'axios'
import { handleResponse, handleError } from './response'

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
// const BASE_URL = 'http://localhost:5500/api' 
const BASE_URL = `${process.env.REACT_APP_BASEURL}/api`

/** @param {string} resource */
const getAll = (resource) => {
  return axios
    .get(`${BASE_URL}/${resource}`)
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {string} id */
const getSingle = (resource, id) => {
  return axios
    .get(`${BASE_URL}/${resource}/${id}`)
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {object} model */
const post = (resource, model) => {
  // console.log(resource, model)
  return axios
    .post(`${BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {object} model */
const put = (resource, model) => {
  return axios
    .put(`${BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {object} model */
const patch = (resource, model) => {
  return axios
    .patch(`${BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError)
}

/** @param {string} resource */
/** @param {string} id */
const remove = (resource, id) => {
  return axios
    .delete(`${BASE_URL}/${resource}`, id)
    .then(handleResponse)
    .catch(handleError)
}

const apiProvider = {
  getAll,
  getSingle,
  post,
  put,
  patch,
  remove
}

export default apiProvider
