import React from "react";
import { CardElement, useStripe, useElements,
  } from "@stripe/react-stripe-js";
  import apiProvider from '../../services/commonService'

export const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();

  const user = JSON.parse(localStorage.getItem('user'));
  const handleSubmit = async (event) => {
    event.preventDefault();
   
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (!error) {
      console.log("Stripe 23 | token generated!", paymentMethod);
      if (paymentMethod.id) {
        // console.log('payment success')
        const body = {
          id: paymentMethod.id,
          userId: user.id,
          planId: props.plan.id,
          amount: props.plan.price
        }
        const response = await apiProvider.post('payment/stripe/charge', body)
        if (response) {
          if (response.HasError) {
            props.close()
          } 
        }
      }
    } else {
    }
  };

  return (
    <form className="Checkout" onSubmit={handleSubmit} style={{ maxWidth: 400 }}>
      <CardElement /> 
      <button className='StripButton'>Pay</button>
    </form>
  );
};
