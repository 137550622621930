import React, { useState, useEffect } from 'react'
import tw from 'twin.macro'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import Hero from 'components/hero/TwoColumnWithInput.js'
import Features from 'components/features/ThreeColWithSideImage.js'
import MainFeature from 'components/features/TwoColWithButton.js'
// import MainFeature2 from 'components/features/TwoColWithTwoHorizontalFeaturesAndButton.js'
import FeatureWithSteps from 'components/features/TwoColWithSteps.js'
import Pricing from 'components/pricing/ThreePlans.js'
import Testimonial from 'components/testimonials/TwoColumnWithImageAndRating.js'
import FAQ from 'components/faqs/SingleCol.js'
import GetStarted from 'components/cta/GetStarted'
import Footer from 'components/footers/MiniCenteredFooter'
import heroScreenshotImageSrc from 'images/hero-screenshot-1.png'
import macHeroScreenshotImageSrc from 'images/hero-screenshot-2.png'
import apiProvider from './services/commonService'

export default (props) => {
  const [faqss, setFaqs] = useState([])

  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`
  const HighlightedText = tw.span`text-primary-500`

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const res = await apiProvider.getAll(`content/faqs/all`)
    if (res) {
      if (!res.HasError) {
        setFaqs(res.result)
      }
    }

  }

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We have Amazing <HighlightedText>Service.</HighlightedText>
          </>
        }
      />
      <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />


      <Pricing
        props={props}
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }


      />
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80',
            heading: 'Amazing User Experience',
            quote:
              'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.',
            customerName: 'Charlotte Hale',
            customerTitle: 'Director, Delos Inc.'
          },
          {
            stars: 5,
            profileImageSrc:
              'https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80',
            heading: 'Love the Developer Experience and Design Principles !',
            quote:
              'Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.',
            customerName: 'Adam Cuppy',
            customerTitle: 'Founder, EventsNYC'
          }
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={faqss}

      />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  )
}
