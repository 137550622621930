import React, { useEffect, useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import illustration from "images/signup-illustration.svg";
import logo from "images/logo.png";
// import googleIconImageSrc from "images/google-icon.png";
// import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/user-plus.svg";
import apiProvider from '../services/commonService'
import { Link } from 'react-router-dom'
import GoogleLoginCustom from './GoogleLogin'
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom';

const Container = tw(ContainerBase)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
// const SocialButton = styled.a`
//   ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
//   .iconContainer {
//     ${tw`bg-white p-2 rounded-full`}
//   }
//   .icon {
//     ${tw`w-4`}
//   }
//   .text {
//     ${tw`ml-4`}
//   }
// `;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

// const Form = tw.form`mx-auto max-w-xs`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white mt-5 first:mt-0`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
// const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
// const IllustrationImage = styled.div`
//   ${props => `background-image: url("${props.imageSrc}");`}
//   ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
// `;
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default (props, {
  // logoLinkUrl = "#",
  // illustrationImageSrc = illustration,
  headingText = "Sign Up For CMMC",
  // socialButtons = [
  //   {
  //     iconImageSrc: googleIconImageSrc,
  //     text: "Sign Up With Google",
  //     url: "https://google.com"
  //   },
  //   {
  //     iconImageSrc: twitterIconImageSrc,
  //     text: "Sign Up With Twitter",
  //     url: "https://twitter.com"
  //   }
  // ],
  submitButtonText = "Sign Up",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "#",
  privacyPolicyUrl = "#",
  // signInUrl = "#"
}) => {
  const [email, setEmail] = useState('')
  // const [valErrors, setValErrors] = useState({})
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [company, setCompany] = useState('')
  const [companySize, setCompanySize] = useState('')
  const [industory, setIndustory] = useState('')
  const [phone, setPhone] = useState('')
  const query = useQuery();

  useEffect(() => {
    let emailStarted = query.get("email");
    if (emailStarted) {
      console.log("email===>", emailStarted);
      setEmail(emailStarted)
    }
  }, [])

  const onSubmit = async () => {
    const body = {
      email,
      password,
      company,
      userType: "CLIENT",
      name: username,
      sizeOfCompany: companySize,
      phone,
      industory,
      // isActive: false,
      planId: '948a2a89-77d8-4bd3-9acd-168550640f2e'
    }
    const response = await apiProvider.post('auth/signup', body)
    if (response) {
      if (response.HasError) {
        toast.success(response.ResultMessages[0].Message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        })
        console.log(response.ResultMessages[0].Message)
      } else {
        // toast.success('Your request is submited to CMMC Admin Please wait for Admin response.', {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined
        // })

        props.history.push('/login')
      }
    }

  }

  const handleUsernameChange = e => {
    // const errs = valErrors
    // console.log(errs)
    // if (errs.username) delete errs.username
    setUsername(e.target.value)
    // setValErrors(errs)
  }

  const handleEmailChange = e => {
    // const errs = valErrors
    // if (errs.email) delete errs.email
    setEmail(e.target.value)
    // setValErrors(errs)
  }

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink >
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                <SocialButtonsContainer>
                  <GoogleLoginCustom props={props}></GoogleLoginCustom>

                </SocialButtonsContainer>
                <DividerTextContainer>
                  <DividerText>Or Sign up with your e-mail</DividerText>
                </DividerTextContainer>
                <Input type="text" onChange={handleUsernameChange} placeholder="User Name" />
                <Input type="email" onChange={handleEmailChange} value={email} placeholder="Email" />
                <Input type="text" onChange={e => setCompany(e.target.value)} placeholder="Company Name" />
                <Input type="password" onChange={e => setPassword(e.target.value)} placeholder="Password" />
                <Input type="number" min="0" value={phone} onChange={e => setPhone(e.target.value)} placeholder="Phone number" />
                <Input type="number" min="0"
                  value={companySize}
                  onChange={e => {
                    setCompanySize(e.target.value)
                  }} placeholder="Company Size" />
                <Input type="text" onChange={e => setIndustory(e.target.value)} placeholder="Industry" />

                <SubmitButton type="submit" disabled={!username || !email || !password || !company || !companySize || !phone || !industory} onClick={onSubmit}>
                  <SubmitButtonIcon className="icon" />
                  <span className="text">{submitButtonText}</span>
                </SubmitButton>
                <p tw="mt-6 text-xs text-gray-600 text-center">
                  I agree to abide by CMMC's{" "}
                  <a href={tosUrl} tw="border-b border-gray-500 border-dotted">
                    Terms of Service
                  </a>{" "}
                  and its{" "}
                  <a href={privacyPolicyUrl} tw="border-b border-gray-500 border-dotted">
                    Privacy Policy
                  </a>
                </p>

                <p tw="mt-8 text-sm text-gray-600 text-center">
                  Already have an account?{" "}
                  <Link to='/login' tw="border-b border-gray-500 border-dotted">
                    Sign In
                  </Link>
                </p>

              </FormContainer>
            </MainContent>
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
}
