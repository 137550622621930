import React from 'react'
import AnimationRevealPage from 'helpers/AnimationRevealPage.js'
import tw from 'twin.macro'
import { css } from 'styled-components/macro' //eslint-disable-line
import Header from 'components/headers/light.js'
import Footer from 'components/footers/MiniCenteredFooter'
import FeatureWithSteps from 'components/features/TwoColWithSteps.js'
import macHeroScreenshotImageSrc from 'images/hero-screenshot-2.png'

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`
  const HighlightedText = tw.span`text-primary-500`
  return (
    <AnimationRevealPage>
      <Header />
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <Footer />
    </AnimationRevealPage>
  )
}
