
export function handleResponse(response) {
    if (response.results) {
      return response.results
    }
  
    if (response.data) {
      return response.data
    }
    // console.log(response)
    return response
  }
  
  export function handleError(error) {
    if (error.data) {
      return error.data
    }
    return error
  }