import React from 'react'
import apiProvider from '../services/commonService'
import { GoogleLogin } from 'react-google-login'

const GoogleLoginCustom = (props) => {
  const responseSucccessGoogle = async (response) => {
    const body = {
      tokenId: JSON.stringify(response)
    }
    const res = await apiProvider.post('auth/google', body)
    if (res) {
      if (res.HasError) {
        console.log(res.ResultMessages[0].Message)
      } else {
        localStorage.setItem('user', JSON.stringify(res.result.user))
        localStorage.setItem('token', JSON.stringify(res.result.user.token))

        // console.log(localStorage.getItem('user'))
        props.props.history.push('/')

      }
    }
  }

  const responseErrorGoogle = (response) => {
    console.log(response)
  }

  return (
    <GoogleLogin
      clientId="520385615228-5gccamgds734dtej32ht0ls6vg9cq95u.apps.googleusercontent.com"
      buttonText="Sign in with Google"
      onSuccess={responseSucccessGoogle}
      onFailure={responseErrorGoogle}
      cookiePolicy={'single_host_origin'}
    />
  )
}


export default GoogleLoginCustom
